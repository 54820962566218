<template>
	<span>
		<div
			v-for="group in contentTextsGroups"
			:class="css(group)"
			class="display"
		>
			<div
				v-for="entry in getFormElement(group)"
			>
				<component
					:is="getElementType(entry)"
					:data="entry"
					:context="context"
					@tap="tap"
				/>
			</div>
		</div>
		<div class="line buttons" style="bottom: 32px">
			<ui-button type="login" color="red" v-hammer:tap="() => back()">{{ $t('back') }}</ui-button>
		</div>
	</span>
</template>

<script>
	import { mixinForms } from '@/components/mixins/forms'

	export default {
		name: 'displayAny',
		mixins: [ mixinForms ],
		methods: {
			back() {
				this.$emit('back')
			},
		}
	}
</script>

<style lang="stylus" scoped>
	@import '../../assets/css/stack-form.styl'

	.display
		p
			color #fff
	.form-content-text-themed
		color white
	.form-btn-themed
		background-color #61789E
		margin-bottom 20px
	.title
		text-align center
		font-size 1.1em
		font-weight bold
		color white
		padding-bottom 10px
		border-bottom 2px solid white
		margin-bottom 24px
	.GRP-1
		background-color white
		border-radius 5px
		padding 16px 16px 4px 16px
		box-shadow 0px 3px 6px alpha(#000, 30%)
		> div
			margin 0 0 12px 0
	.news-emails
		color white
		margin-top 20px
</style>
