<template>
	<div class="player-infos" :class="{ light: isOtherUser }">

		<!-- User infos -->
		<div class="box blue">
			<div class="box-title">{{ userName() }}</div>
			<div class="box-content">
				<div class="corner"></div>

				<div class="user-infos blue">
					<div class="info">{{ practiceField() }}</div>
					<div class="info">{{ practiceName() }}</div>
					<div class="separator"></div>
					<div class="info transparent">{{ $t('active_since', { activeSince: activeSince() }) }}</div>
				</div>
			</div>
		</div>
		<!-- Accreditations received -->
		<template
			v-if="!isOtherUser"
		>
			<div class="box purple">
				<div class="box-title">{{ $t('accreditations') }}</div>
				<div class="box-content">
					<div class="corner"></div>
					<div class="button purple" v-hammer:tap="() => this.$emit('changePage', { page: 'accreditations' })">{{ $t('see_my_accreditations') }} <span class="triangle"></span></div>
				</div>
			</div>
		</template>
		<!-- Clinic progress -->
		<div class="box yellow">
			<div class="box-title">{{ $t('clinic_progress') }}</div>
			<div class="box-content">
				<div class="corner"></div>

				<div class="progress-bar">
					<div class="progress" :style="progressBar()"></div>
					<div class="star"><span>{{ level() }}</span></div>
					<div class="infos">{{ unitsInLevel() }} / {{ unitsNeeded() }}</div>
				</div>
				<div class="treated-patients">
					<div class="label">{{ $t('treated_patients') }}</div>
					<div class="value">{{ unitsTotal() }} <div class="heart"></div></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mixinUser } from '@/components/mixins/user'

export default {
	name: 'player-infos',
	mixins: [ mixinUser ],
	props: [ 'user', 'isOtherUser' ],
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app' ]),
	},
	methods: {}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.player-infos
	&.light
		.box .box-content .corner
			background-color #fff
	.box
		margin 0 0 16px 0
		box-shadow 1px 1px 2px alpha(dark, 25%)
		&.blue
			.box-title
				background-color #A6CAE2
				text-transform none
				color #1D6381
			.box-content
				background-color #E0EEFC
				.corner
					width 20px
					height 12px
					&:before
						left 0px
						top -2px
						border-width 12px 20px 0 0
						border-color #A6CAE2 transparent transparent transparent
		&.purple
			.box-title
				background-color #61789E
			.box-content
				background-color #C2D3EE
				.corner
					transform rotate(-10deg)
					&:before
						left 1px
						top -2px
						border-color #61789E transparent transparent transparent
		&.yellow
			.box-title
				background-color #FFC758
			.box-content
				background-color #FFEAD2
				.corner
					right auto
					left -3px
					&:before
						left 1px
						top -2px
						border-width 0 16px 16px 0
						border-color transparent #FFC758 transparent transparent
		&.red
			.box-title
				background-color #FF9677
			.box-content
				background-color #FFB099
				.corner
					width 20px
					height 12px
					&:before
						left -1px
						top -1px
						border-width 12px 20px 0 0
						border-color #FF9677 transparent transparent transparent
		.box-title
			color #fff
			height 40px
			padding 4px
			line-height 32px
			text-align center
			font-weight 700
			font-size 1.8rem
			text-transform uppercase
		.box-content
			padding 16px
			border 1px solid alpha(#000, 10%)

			.corner
				position absolute
				right -3px
				bottom -3px
				width 16px
				height 16px
				background-color dark
				&:before
					content ''
					position absolute
					left -1px
					top -1px
					width 0
					height 0
					border-style solid
					border-width 16px 16px 0 0
					border-color #A6CAE2 transparent transparent transparent


			.user-infos.blue
				.info
					height 24px
					line-height @height
					margin 0 0 8px 0
					background-color #A6CAE2
					color #1D6381
					text-align center
					&.transparent
						background-color transparent
					&:last-child
						margin-bottom 0
				.separator
					height 2px
					margin 0 0 8px 0
					background-color #A6CAE2

			.button
				display block
				width 100%
				height 40px
				flex center center
				border-radius 16px
				text-align center
				line-height @height
				font-weight 700
				cursor pointer
				box-shadow 2px 2px 4px alpha(dark, 50%)
				user-select none
				&:active
					transform translateY(2px)
					box-shadow none
				&.purple
					background-color #61789E
					color #fff
				.triangle
					display inline-block
					margin 0 0 0 16px
					width 0
					height 0
					border-style solid
					border-width 8px 0 8px 16px
					border-color transparent transparent transparent #fff
			.progress-bar
				height 16px
				margin 16px auto 32px auto
				width calc(100% - 32px)
				background-color yellow
				border-radius 16px
				border 3px solid white
				.progress
					height 100%
					background-color red
					border-top-left-radius 16px
					border-bottom-left-radius 16px
				.star
					position absolute
					left -16px
					top calc(50% - 36px)
					padding-left 3px
					width 64px
					height 64px
					color #fff
					text-align center
					line-height 70px
					font-size 2.4rem
					&:before
						content ''
						position absolute
						left 0
						top 0
						width 100%
						height 100%
						background url(../../../assets/img/star-red.svg) center center no-repeat
						background-size 100% auto
						transform rotate(-12deg)
				.infos
					position absolute
					right 0
					bottom 16px
					height 24px
					color red
					font-size 1.4rem
					line-height @height
			.treated-patients
				flex center center
				border-top 1px solid #fff
				padding 16px 0 0 0
				color red
				line-height 24px
				.label
					width 50%
					white-space nowrap
					font-weight 700
				.value
					width 50%
					text-align right
					font-size 2rem
					.heart
						float right
						width 24px
						height 24px
						margin 0 0 0 8px
						background url(../../../assets/img/profile/heart.svg) center center no-repeat
						background-size contain


</style>
