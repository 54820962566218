<template>
	<div class="profile" :class="mode">
		<div class="profile-bg"></div>
		<div class="profile-content">

			<div class="modes" v-scrollbar>
				<div ref="modes">
					<div class="modes-content">
						<div class="title"><img :src="myProfileImg" :alt="$t('my_profile')"></div>

						<div v-show="mode == 'viewer'" class="viewer">
							<div class="row">
								<div class="full">
									<div class="label">{{ $t('we_hereby') }}</div>
									<div class="value">{{ getFirstName }} {{ getLastName }}</div>
								</div>
							</div>
							<div class="row">
								<div class="full">
									<div class="label">{{ $t('known_as') }}</div>
									<div class="value">{{ getUserName }}</div>
								</div>
							</div>
							<div class="row">
								<div class="full">
									<div class="label">{{ $t('is_certified') }}</div>
									<div class="value">{{ getPracticeFieldName }}</div>
								</div>
							</div>
							<div class="row">
								<div class="half">
									<div class="label">{{ $t('practices_at') }}</div>
									<div class="value">{{ getPracticeName }}</div>
								</div>
								<div class="half">
									<div class="label">{{ $t('since_the_date_of') }}</div>
									<div class="value">{{ readableDate(getCreatedAt)}}</div>
								</div>
							</div>
							<div class="row">
								<div class="full">
									<div class="label">{{ $t('consented') }}</div>
									<div class="value">{{ getEmail }}</div>
								</div>
								<div class="tampon"></div>
							</div>
							<div class="footer">
								<div class="signature">
									<div class="label">{{ $t('authorized_by') }}</div>
									<div class="value">Level Up MD</div>
									<div class="button" v-hammer:tap="() => set_mode(EDITOR)">{{ $t('edit_profile') }}</div>
								</div>
								<div class="stamp"></div>
							</div>
						</div>

						<div v-show="mode == EDITOR" class="editor">
							<ValidationObserver
								ref="editor"
								v-slot="{ handleSubmit }"
								tag="div"
							>
								<form
									class="form"
									method="post"
									@submit.prevent="handleSubmit(save)"
									@keyup.enter='handleSubmit(save)'
								>
									<div class="row">
										<div class="half">
											<validation-provider
												mode="lazy"
												name="firstName"
												rules="alpha_spaces|max:20"
												v-slot="{ errors }"
												tag="div"
												class="line"
											>
												<label for="firstName" class="label">{{ caps($t('firstName')) }}</label>
												<input type="text" v-model="firstName" id="firstName" class="input">
												<span v-if="errors.length" class="error">{{ errors[0] }}</span>
											</validation-provider>
											<p class="hint">{{ $t('hidden_from_players') }}</p>
										</div>
										<div class="half">
											<validation-provider
												mode="lazy"
												name="lastName"
												rules="alpha_spaces|max:20"
												v-slot="{ errors }"
												tag="div"
												class="line"
											>
												<label for="lastName" class="label">{{ caps($t('familyName')) }}</label>
												<input id="lastName" v-model="lastName" type="text" class="input">
												<span v-if="errors.length" class="error">{{ errors[0] }}</span>
											</validation-provider>
											<p class="hint">{{ $t('hidden_from_players') }}</p>
										</div>
									</div>
									<div class="row">
										<div class="full">
											<validation-provider
												mode="lazy"
												name="userName"
												rules="alpha_num|max:15"
												v-slot="{ errors }"
												tag="div"
												class="line"
											>
												<label for="userName" class="label">{{ caps($t('username')) }}</label>
												<input id="userName" v-model="userName" type="text" class="input">
												<span v-if="errors.length" class="error">{{ errors[0] }}</span>
											</validation-provider>
										</div>
									</div>
									<div class="row">
										<div class="full">
											<validation-provider
												mode="lazy"
												name="practice_field"
												rules="required"
												v-slot="{ errors }"
												tag="div"
												class="line"
											>
												<label for="practiceField" class="label">{{ caps($t('practice_field')) }}</label>
												<select id="practiceField" v-model="practiceFieldId" class="input">
													<option v-for="({ html, id }) in practiceFields" :value="id" v-html="html"></option>
												</select>
												<span v-if="errors.length" class="error">{{ errors[0] }}</span>
											</validation-provider>
										</div>
									</div>
									<div class="row">
										<div class="half">
											<validation-provider
												mode="lazy"
												name="clinicName"
												rules="max:30"
												v-slot="{ errors }"
												tag="div"
												class="line"
											>
												<label for="practiceName" class="label">{{ caps($t('clinicName')) }}</label>
												<input id="practiceName" v-model="practiceName" type="text" class="input">
												<span v-if="errors.length" class="error">{{ errors[0] }}</span>
											</validation-provider>
										</div>
										<div class="half">
											<div class="label">{{ caps($t('account_created_on')) }}</div>
											<input type="text" class="input" disabled :value="readableDate(getCreatedAt)">
										</div>
									</div>
									<div class="row">
										<div class="full">
											<validation-provider
												mode="lazy"
												name="email"
												rules="email"
												v-slot="{ errors }"
												tag="div"
												class="line"
											>
												<label for="email" class="label">{{ caps($t('email')) }}</label>
												<input id="email" v-model="email" type="email" class="input" disabled>
												<span v-if="errors.length" class="error">{{ errors[0] }}</span>
											</validation-provider>
										</div>
									</div>

									<div class="row buttons">
										<div class="button half red" v-hammer:tap="() => cancel()">{{ $t('quit_without_saving') }}</div>
										<div class="button half blue" :class="{ disabled: saved }" v-hammer:tap="() => handleSubmit(save)">
											{{ $t('save_changes') }}
											<transition name="saved">
												<div v-show="saved" class="stamp"></div>
											</transition>
										</div>
									</div>
								</form>
							</ValidationObserver>
						</div>
					</div>
				</div>
			</div>

			<div class="close" v-hammer:tap="() => goto({ name: 'office' })"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import { getValidation } from '@/js/utils/validations'
import { _apollo } from '@/js/managers/_apollo'
import { cloneDeep } from 'lodash'
import { LANGUAGE } from '@/js/factory/language'
import { capitalizeOnLanguage } from '@/js/utils/utils'

export default {
	name: 'profile',
	components: {
		ValidationObserver,
		ValidationProvider
	},
	data() {
		return {
			EDITOR: 'editor',
			mode: 'viewer',
			saved: false,
			email: null,
			userName: null,
			firstName: null,
			lastName: null,
			createdAt: null,
      practiceFieldId: null,
			practiceFields: [],
			practiceName: null
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		...mapState({
			id: state => state.user.logged_user.id,
			getEmail: state => state.user.logged_user.email,
			getUserName: state => state.user.logged_user.userName,
			getFirstName: state => state.user.logged_user.firstName,
			getLastName: state => state.user.logged_user.lastName,
			getCreatedAt: state => state.user.logged_user.createdAt,
			getPracticeFieldId: state => state.user.logged_user.profile.practice_field.id,
			getPracticeFieldName: state => state.user.logged_user.profile.practiceFieldName,
			getPracticeName: state => state.user.logged_user.profile.clinic_name,
      getProfile: state => state.user.logged_user.profile,
		}),
		myProfileImg() {
			const language = LANGUAGE.getData()
			return this.$medias.find({ tags: [ 'profile', 'title', language ] })
		}
	},
	watch: {
		mode() {
			if (this.$refs.modes) {
				this.$refs.modes.scrollTop = 0
			}
		}
	},
	methods: {
		getValidation,
		caps(value) {
			return capitalizeOnLanguage(['en'], LANGUAGE.getData(), value)
		},
		readableDate(date) {
			return Vue.dateFormat
				? Vue.dateFormat(date, 'readableDate')
				: date
		},
		async set_mode(mode) {
			this.mode = mode
			if (mode === this.EDITOR) {
			  const query = 'CONTENT_FORM_SELECT_INPUT'
        const variables = { id: '5efb60dbc327c600060ad75c' }
        const { selectableElements = [] } = (await _apollo({ query, variables })) || {}
        if (!selectableElements.length) return
        const practiceFields = selectableElements.map(x => {
          const { id = null, option: { html = '', id:option_id } = {} } = x
          return {
            html,
            id,
            option_id
          }
        })
				const {
					getEmail: email,
					getUserName: userName,
					getFirstName: firstName,
					getLastName: lastName,
					getPracticeFieldId: practiceFieldId,
					getPracticeName: practiceName
				} = this
				Object.assign(this, {
					email,
					userName,
					firstName,
					lastName,
					practiceFieldId,
					practiceFields,
					practiceName
				})
			}
		},
		cancel() {
			this.set_mode('viewer')
		},
		async save() {
			if (!this.saved) {
				this.saved = true
				setTimeout(() => {
					this.set_mode('viewer')
					this.saved = false
				}, 1500)
			}
			const {
				email,
				userName,
				firstName,
				lastName,
				practiceFieldId:id,
				practiceName:clinic_name,
        getProfile:profile,
        practiceFields
			} = this
      const cloneProfile = cloneDeep(profile)
      delete cloneProfile.practiceFieldName
      const { option_id } = practiceFields.find(x => x.id === id)
			const variables = {
				input: {
					email,
					userName,
					firstName,
					lastName,
				},
        profile: JSON.stringify(Object.assign(cloneProfile, {
          clinic_name,
          practice_field: Object.assign(cloneProfile.practice_field, {
            id,
            option_id
          })
        }))
			}
			await Apollo.mutate('UPDATE_CLIENT_USER_PROFILE', variables)
			await this.$store.dispatch('get_current_user')
		},
		goto(data) {
			this.$router.push(data)
		},
	},
	async mounted() {
		const value = await Apollo.query('SCHEMA', { id: process.env.VUE_APP_APOLLO_SCHEMA_ID })
		if (value) await this.$store.dispatch('schemaSet', { value })
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');

#app.mobile
	.profile
		.profile-content
			border-width 4px

.profile
	absolute 0
	flex center center
	transition 0.25s easeOutQuart
	&.profile-leave-to
		opacity 0
	&.profile-enter
		opacity 0
		.profile-content
			transform translateY(-48px)
	.profile-bg
		absolute 0
		background-color alpha(#61789E, 70%)
	.profile-content
		width 100%
		height 100%
		max-width 450px
		max-height 700px
		box-shadow 0 0 16px 2px alpha(#000, 50%)
		background-color #FFFEF3
		border 20px solid #969595
		border-left-color #3E3E3E
		border-bottom-color #6B6B6B
		transition 0.25s easeOutQuart
		.modes
			height 100%
			.modes-content
				padding 40px 24px 24px 24px
				> .title
					width 100%
					flex center center
					margin 0 0 40px 0
					font-family 'Old English'
					font-size 3.2rem
					line-height 40px
					text-align center
					user-select none
					&:before
						content ''
						position absolute
						left calc(50% - 32px)
						bottom calc(100% + 16px)
						height 16px
						width 64px
						background url(../../assets/img/profile/header-shape.svg) center center no-repeat
						background-size contain
					img
						height 32px

		.viewer
			.row, .footer
				width 100%
				margin 0 0 24px 0
				text-align center
				flex flex-start center
				> .full
					width 100%
				> .half
					width calc(50% - 16px)
					&:first-child
						margin 0 16px 0 0
					&:last-child
						margin 0 0 0 16px
				> .signature
					margin 8px 0 0 0
					width 65%
					min-height 120px
					.label
						text-align left
					.button
						width 100%
						height 40px
						margin 16px 0 0 0
						border-radius 8px
						background-color #61789E
						line-height @height
						color #fff
						cursor pointer
						user-select none
						&:active
							transform translateY(2px)
						&.disabled
							opacity 0.6
							cursor not-allowed
							transform none
				.label
					width 100%
					font-family 'Tangerine', cursive
					font-size 2.4rem
					color #9B9B97
					user-select none
				.value
					width 100%
					height 32px
					border-bottom 1px solid #ddd
					color blue
					font-size 1.6rem
					font-weight 700
					line-height @height
					white-space nowrap

				.stamp
					width 40%
					height 100px
					background url(../../assets/img/profile/stamp-signature.svg) right center no-repeat
					background-size contain

		.editor
			.row
				width 100%
				margin 0 0 24px 0
				text-align center
				flex flex-start center
				> .full
					width 100%
				> .half
					width calc(50% - 16px)
					&:first-child
						margin 0 16px 0 0
					&:last-child
						margin 0 0 0 16px
				.label
					width 100%
					font-size 1.4rem
					color #9B9B97
					user-select none
				.input
					width 100%
					height 32px
					background transparent
					border none
					border-bottom 1px solid #ddd
					line-height @height
					white-space no-wrap
					font inherit
					color blue
					font-weight 700
					outline none
					text-align center
					&:disabled
						color alpha(blue, 50%)
						user-select none
						border-color transparent
					&:focus
						border-color blue
						background-color alpha(blue, 10%)
						// box-shadow 0 1px blue
				select.input
					appearance none
					border-radius 0
					background url(../../assets/img/profile/dropdown.svg) right 8px center no-repeat
					background-size 10px
					text-align-last center
					text-align center
					-ms-text-align-last center
					-moz-text-align-last center

				.error
					display block
					min-height 24px
					flex flex-start center
					margin 4px 0 0 0
					padding-left 32px
					background url(../../assets/img/login/alert-triangle.svg) left top no-repeat
					background-size 24px 24px
					color #f44336
					font-weight 700
					font-size 1.1rem
					line-height 12px
				.hint
					margin 4px 0 0 0
					color #B9BBBA
					font-size 1.1rem
					opacity 0.75
					user-select none

			.buttons
				flex center center
				.button
					height 64px
					padding 0 16px
					border-radius 12px
					flex center center
					color #fff
					font-size 1.6rem
					font-weight 700
					cursor pointer
					user-select none
					&:active
						transform translateY(2px)
						&.disabled
							transform none
					&.red
						background-color #E96161
					&.blue
						background-color #61789E
						&.disabled
							background-color alpha(#61789E, 50%)
					&.disabled
						cursor default
					.stamp
						position absolute
						right -16px
						bottom -16px
						width 100px
						height 100px
						background url(../../assets/img/profile/saved.svg) right center no-repeat
						background-size contain
						transform rotate(20deg)
						&.saved-enter-active
							transition 0.4s easeInQuart
						&.saved-enter
							transform scale(3)
							opacity 0



		.close
			position absolute
			right 8px
			top 8px
			width 40px
			height 40px
			flex center center
			background #AF7171 url(../../assets/img/profile/close-profile.svg) center center no-repeat
			background-size 28px
			border-radius 12px
			cursor pointer
			&:active
				transform translateY(2px)


</style>
