<template>
	<div class="office">
		<div class="office-content" :style="{ width: bg_width + 'px', left: bg_left + 'px' }">
			<img :src="background_url" class="bg" ref="bg" @load="bg_loaded()" draggable="false">

			<div class="element profile" :class="app.day_moment" v-hammer:tap="() => goto({ name: 'office-profile' })">
				<div class="image"></div>
				<div class="element-title">{{ caps($t('my_profile')) }}</div>
			</div>
			<div class="element leaderboard" :class="app.day_moment" v-hammer:tap="() => goto({ name: 'office-leaderboard' })">
				<div class="image" :class="{ pending: isPending }">
					<div
						v-if="isPending"
						class="computer-screen"
					>
						<img class="users" src="../assets/img/profile/users.svg" alt="">
						<div class="pending-dot blink"></div>
					</div>
					<div class="flashing"></div>
				</div>
				<div class="element-title">{{ caps($t('leaderboard')) }}</div>
			</div>
			<div class="element stats" :class="app.day_moment" v-hammer:tap="() => goto({ name: 'office-stats' })">
				<div class="image"></div>
				<div class="element-title">{{ caps($t('my_stats_short')) }}</div>
			</div>
			<div class="element plant" :class="app.day_moment">
				<div class="image"></div>
			</div>

		</div>

		<div class="home-button" v-hammer:tap="()=> goto({ name: 'home' })"></div>
		<div class="room-title">{{ caps($t('your_profile')) }}</div>

		<transition name="profile">
			<profile v-if="$route.name === 'office-profile'" />
		</transition>

		<transition name="stats">
			<stats v-if="$route.name === 'office-stats'" />
		</transition>

		<transition name="leaderboard">
			<leaderboard v-if="$route.name === 'office-leaderboard'" />
		</transition>
	</div>
</template>

<script>
import { mixinUser } from '@/components/mixins/user'
import { mapGetters, mapState } from 'vuex'
import Profile from '@/components/office/profile'
import Stats from '@/components/office/stats'
import Leaderboard from '@/components/office/leaderboard'
import {manageEventListeners} from "@/js/managers/manageEventListeners"
import { capitalizeOnLanguage } from '@/js/utils/utils'
import { LANGUAGE } from '@/js/factory/language'

export default {
	name: 'office',
	mixins: [ mixinUser ],
	data() {
		return {
			bg_width: 0,
			bg_left: 0,
			manageEventListeners: manageEventListeners()
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		isPending() {
			return !!this.incomingRelationships.length
		},
		background_url() {
			return this.$medias.find({ tags: [ 'background', this.app.day_moment, 'floor-2' ] })
		},
	},
	methods: {
		caps(value) {
			return capitalizeOnLanguage(['en'], LANGUAGE.getData(), value)
		},
		bg_loaded() {
			this.resize()
		},
		goto(data) {
			this.$router.push(data)
		},
		resize() {
			if (this.$refs.bg) {
				setTimeout(() => {
					this.bg_width = this.$refs.bg.clientWidth
					this.bg_left = (window.innerWidth - this.$refs.bg.clientWidth) / 2
					this.$emit('loaded')
				}, 100)
			}
		}
	},
	mounted() {
		this.manageEventListeners.add(window, 'resize', this.resize)
	},
	components: { Profile, Stats, Leaderboard },
	beforeDestroy() {
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'
@import '../assets/css/pending'

#app.mobile
	.office
		.bg
			transform translateX(-61%)
		.element
			&.profile
				top 20%
				left 40.5%
				height 25%
				width 9%
				.element-title
					top 0
					left auto
					right 47%
					width 53%
					min-width 80px
					height 28px
					line-height @height
			&.leaderboard
				bottom 22%
				left 41%
				width 11%
				height 22%
			&.stats
				bottom 22%
				left 53%
				height 15%
				width 6%
				.element-title
					left 13%
			&.plant
				bottom 18%
				left 60%
				width 5%
				height 25%

#app.tablet
	.office
		.bg
			transform translateX(-61%)
		.element
			&.profile
				top 10%
				left 35%
				height 25%
			&.leaderboard
				bottom 22%
				left 34%
				width 12%
				height 25%
			&.stats
				bottom 22%
				left 48%
				height 15%
				width 7%
				.element-title
					left 25%
			&.plant
				bottom 18%
				left 59%
				width 5%

.office
	absolute 0
	.office-content
		absolute 0
		.bg
			position absolute
			left 50%
			bottom 0
			height 100%
			transform translateX(-50%)
		.element
			position absolute
			height 20%
			width 10%
			cursor pointer
			&.profile
				top 20%
				left 45%
				height 25%
				&.night .image
					background-image url(../assets/img/profile/diploma-night.svg)
				.image
					background-image url(../assets/img/profile/diploma.svg)
				.element-title
					left 10%
					top 0
					width 47%
					border-radius 12px 0 0 0
			&.leaderboard
				bottom 22%
				left 45%
				&.night .image
					background-image url(../assets/img/profile/computer-night.svg)
				.image
					background-image url(../assets/img/profile/computer.svg)
					&.pending
						background-image url(../assets/img/profile/computer-pending.svg)
				.element-title
					top calc(100% + 8px)
					right 0
					left 0
					border-radius 0 0 12px 12px
			&.stats
				bottom 22%
				left 55%
				height 14%
				&.night .image
					background-image url(../assets/img/profile/files-night.svg)
				.image
					background-image url(../assets/img/profile/files.svg)
				.element-title
					top calc(100% + 8px)
					right 0%
					left 50%
					border-radius 0 0 12px 12px
			&.plant
				bottom 18%
				left 65%
				cursor default
				&.night .image
					background-image url(../assets/img/profile/plant-night.svg)
				.image
					background-image url(../assets/img/profile/plant.svg)
			.image
				width 100%
				height 100%
				background bottom right no-repeat
				background-size contain
			.element-title
				position absolute
				height 32px
				background-color red
				color #fff
				line-height @height
				white-space nowrap
				text-align center

	.home-button
		position absolute
		right 16px
		top 16px
		width 40px
		height 40px
		background yellow url(../assets/img/home.svg) center center no-repeat
		background-size 24px 24px
		cursor pointer
		box-shadow 0px 3px 6px alpha(#000, 30%)
		&:before
			content ''
			absolute -8px
		&:hover
			background-color darken(yellow, 10%)
		&:active
			transform translateY(3px)
			background-color darken(yellow, 10%)
			box-shadow 0px 0px 0 alpha(#000, 30%)

	.room-title
		position absolute
		right 64px
		top 16px
		height 40px
		width 200px
		padding 0 16px 0 0
		background red
		cursor default
		user-select none
		box-shadow 0px 3px 6px alpha(#000, 30%)
		color #fff
		text-align right
		line-height @height
		border-radius 0 0 0 20px

	.computer-screen
		width 100%
		height 80%
		display flex
		flex-direction row
		justify-content center
		align-items center
		.users
			width 25%

</style>
