<template>
	<div class="colleagues">
		<div class="tab-colleagues" v-scrollbar>
			<div>
				<div class="tab-content">
					<div
						class="pending-warning-box"
						v-if="(this.incomingRelationships || []).length"
						v-hammer:tap="() => viewIncoming(true)"
					>
						<div class="users">
							<img src="@/assets/img/profile/users.svg" alt="">
						</div>
						<div class="details">
							{{ $t('you_have_new_colleagues', { incomingRelationships: (incomingRelationships || []).length, s: (incomingRelationships || []).length > 1 ? 's' : ''}) }}
						</div>
						<div class="view"><div class="arrow"></div><div class="text">{{ $t('View') }}</div></div>
					</div>
					<div v-if="(this.users || []).length" class="colleagues-content">
						<div
							v-for="(user, index) in users"
							class="rank"
							v-hammer:tap="() => openProfile({ user })"
						>
							<div class="rank-content" v-hammer:tap="() => selectUser({ user })">
								<div class="info name">{{ userName({ user} ) }}</div>
								<div class="info">{{ practiceField({ user }) }}</div>
								<div class="info">Level {{ level({ user }) }}</div>
							</div>
							<div class="rank-actions">
								<div class="score">{{ unitsTotal({ user }) }} <div class="heart"></div></div>
								<div
									class="action"
									:class="[ actionClass({ user }) ]"
									v-hammer:tap="() => colleagueAction(actionClass({ user }), { user })"
								><div class="users"></div></div>
							</div>
						</div>
					</div>
					<div v-else class="colleagues-content">
						<div class="empty">
							<div class="icon"></div>
							<p class="orange" v-html="$t('you_dont_have_colleagues')"></p>
							<div
								class="button orange"
								v-hammer:tap="() => $emit('tab-select', 'medals')"
							>
								{{ $t('add_some_colleagues') }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="accept-refuse">
			<div v-if="viewIncomingState" class="accept-refuse">
				<div class="accept-refuse-content" v-scrollbar>
					<div>
						<div>
							<div class="accept-refuse-colleagues">
								<div
									class="colleagues-info"
									v-for="user in incomingRelationships"
								>
									<div class="block">
										<div class="info-line">
											<div class="username-bullet"></div>
											<div class="name">{{ userName(user) }}</div>
											<div class="value">{{ unitsTotal(user) }} <div class="heart"></div></div>
										</div>
										<div class="info-line">
											<div class="practice-bullet"></div>
											<div class="name secondary">{{ practiceField(user) }}</div>
										</div>
										<div class="info-line">
											<div class="level-bullet"></div>
											<div class="name secondary">Level {{ level(user) }}</div>
										</div>
										<div
											class="btns"
											v-if="isUserPending(user) && colleague.state === null"
										>
											<div
												class="btn-refuse"
												v-hammer:tap="() => refuse(user)"
											>Refuse</div>
											<div
												class="btn-accept"
												v-hammer:tap="() => accept(user)"
											>Accept</div>
										</div>
										<div
											class="btns"
											v-if="colleague.state === ACCEPT"
										>
											<div class="colleague added">{{ $t('colleague_added') }}</div>
										</div>
										<div
											class="btns"
											v-if="colleague.state === REFUSE"
										>
											<div class="colleague refused">{{ $t('request_denied') }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<transition name="back-bar">
			<div v-if="viewIncomingState" class="back-bar">
				<div class="button back" v-hammer:tap="() => viewIncoming(false)">{{ $t('back') }}</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mixinUser } from '@/components/mixins/user'
import { mapState } from 'vuex'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'colleagues',
	mixins: [ mixinUser ],
	data() {
		return {
			viewIncomingState: false,
			colleague: {
				state: null
			}
		}
	},
	computed: {
		...mapState({
		}),
	},
	methods: {
		goto(data) {
			this.$router.push(data)
		},
		acceptRefuse(bState, { user }) {
			const { ACCEPT, REFUSE } = this
			const { id: requesterId } = user
			const state = bState ? ACCEPT : REFUSE
			this.colleague.state = state
			setTimeout(async () => {
				await Apollo.mutate(`${state}_RELATIONSHIP_REQUEST`, { requesterId })
				await this.$store.dispatch('get_current_user')
				if (!(this.incomingRelationships || []).length) this.viewIncoming(false)
			}, 1500)
		},
		async accept(user) {
			this.acceptRefuse(true, user)
		},
		async refuse(user) {
			this.acceptRefuse(false, user)
		},
		viewIncoming(state) {
			this.viewIncomingState = state
		},
		emit({ action = null, user = null }) {
			this.$emit('colleagues-manager', { action, user } )
		},
		colleagueAction(action, user) {
			if (action && user) this.emit({ action, user })
		}

	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.colleagues
	darkblue = #61789E
	overflow hidden
	.tab-colleagues
		height 100%
		background-color #fff
		.tab-content
			height 100%
			padding 16px
			.colleagues-content
				.rank
					flex flex-start flex-start
					padding 16px 0
					border-bottom 1px solid darkblue
					&:first-child
						padding-top 0
					&:last-child
						border-bottom none
					.rank-content
						width calc(100% - 72px)
						line-height 24px
						cursor pointer
						.info
							color #999
							padding-left 24px
							&.name
								font-weight 700
								color #000
								&:before
									content ''
									position absolute
									left 0
									top 6px
									width 12px
									height 12px
									background-color yellow
									border-radius 50%
							&.role
								&:before
									content ''
									position absolute
									left 2px
									top 8px
									width 8px
									height 8px
									background-color red
									border-radius 50%
					.rank-actions
						width 72px
						text-align right
						.score
							flex center center
							margin 0 0 8px 0
							.heart
								display inline-block
								width 24px
								height 24px
								margin 0 0 0 8px
								background url(../../../assets/img/profile/heart.svg) center center no-repeat
								background-size contain
						.action
							height 40px
							flex center center
							padding 0 8px
							border-radius 4px
							background-color darkblue
							cursor pointer
							color #fff
							font-size 1.8rem
							font-weight 700
							&:before
								content '+'
							&.active
								background-color #F8A2A2
								&:before
									content '-'
							&.add
								&:before
									content '+'
							&.remove
								cursor pointer
								background-color #FBCBBD
								&:before
									content: '-'
									font-size 1.2em
									display inline-block
									height 30px
							.users
								display inline-block
								margin 0 0 0 4px
								width 24px
								height 24px
								background url(../../../assets/img/profile/users.svg) center center no-repeat
								background-size contain
				.empty
					width 100%
					height 100%
					min-height 300px
					flex center center
					flex-direction column
					color #999
					text-align center
					p
						margin-bottom 20px
					.icon
						width 128px
						height 128px
						margin 0 0 8px 0
						background url(../../../assets/img/profile/no-colleague-found.svg) center center no-repeat
						background-size contain

			.pending-warning-box
				flex center center
				height 72px
				margin 0 0 16px 0
				border-radius 8px
				-webkit-mask-image -webkit-radial-gradient(white, black)
				background-color #FFE9BF
				cursor pointer
				overflow hidden
				>>>.users
					display flex
					justify-content center
					align-items center
					width 18%
					height 100%
					background-color #FFB524
					img
						width 30px
					&:after
						margin -20px 0 0 0
						content ''
						display inline-block
						width 10px
						height 10px
						border-radius 50%
						background-color #fff
				>>>.details
					display flex
					flex-direction column
					justify-content center
					align-items center
					width 64%
					height 100%
				>>>.view
					flex center center
					flex-direction column
					width 18%
					height 100%
					color #FF6133
					font-weight 600
					.arrow
						width 24px
						height 24px
						background url(../../../assets/img/profile/arrow-right.svg) center center no-repeat
						background-size contain
			.new-colleague
				color #FF6133
				font-weight 600

	.accept-refuse
		position absolute
		left 0
		top 0
		width 100%
		height calc(100% - 56px)
		transition 0.25s easeOutQuart
		// overflow hidden
		&.accept-refuse-enter, &.accept-refuse-leave-to
			.accept-refuse-content
				transform translateX(100%)
		.accept-refuse-content
			height 100%
			transition 0.25s easeOutQuart
			background-color #fff
		.btns
			flex center center
			margin 8px 0 0 0
			.btn-refuse, .btn-accept
				cursor pointer
				width calc(50% - 8px)
				height 40px
				border-radius 8px
				font-weight 600
				color #fff
				line-height 40px
				white-space nowrap
				text-align left
				padding 0 24px
				&:first-child
					margin 0 8px 0 0
				&:last-child
					margin 0 0 0 8px
				&:active
					transform translateY(2px)
			.btn-refuse
				background #E96161 url(../../../assets/img/profile/forbidden.svg) right 24px center no-repeat
				background-size auto 24px
			.btn-accept
				background #61789E url(../../../assets/img/profile/users-plus.svg) right 24px center no-repeat
				background-size auto 24px
			.colleague
				height 40px
				width 80%
				display flex
				justify-content center
				align-items center
				border-radius 8px
				&.added
					background-color #FFECE6
				&.refused
					background-color #E96161

		.colleagues-info
			padding 16px 16px 0 16px
			&:after
				content ''
				display block
				border-bottom 1px solid dark
			&:last-child
				&:after
					border-bottom none
				.block
					padding 0
			.block
				display flex
				padding 0 0 16px 0
				flex-direction column
				.info-line
					display flex
					flex-direction row
					width 100%
					min-height 24px
					line-height 24px
					.username-bullet
						width 25px
						&:after
							content ''
							display inline-block
							width 15px
							height 15px
							border-radius 50%
							background-color #FFB524
					.practice-bullet
						width 25px
						&:after
							content ''
							display inline-block
							margin 0 0 0 2px
							width 10px
							height 10px
							border-radius 50%
							background-color #FF6133
					.level-bullet
						width 25px
					.name
						width calc(100% - 70px)
						&.secondary
							color #999
					.value
						text-align right
						font-size 2rem
						color #FF6133
						width 50px
						.heart
							float right
							width 24px
							height 24px
							margin 0 0 0 8px
							background url(../../../assets/img/profile/heart.svg) center center no-repeat
							background-size contain

	.back-bar
		position absolute
		left 0
		right 0
		bottom 0
		height 56px
		background-color dark
		flex flex-start center
		padding 0 16px
		transition 0.25s easeOutQuart
		&.back-bar-enter, &.back-bar-leave-to
			transform translateY(100%)
		.button
			height 40px
			flex center center
			padding 0 16px
			border-radius 8px
			background-color darkblue
			cursor pointer
			color white
			user-select none
			font-size 1.8rem
			font-weight 700
			&:active
				transform translateY(2px)
			&.back
				background-color #e96161

	.button
		border-radius 8px
		padding 10px 30px
		color white
		font-weight normal
		font-size .85em
		cursor pointer
		&.orange
			background-color #FF6638
	p.orange
		color #FF6638
		>>>p
			margin-bottom 20px
</style>
