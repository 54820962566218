<template>
	<div class="stats">
		<div class="stats-bg" v-hammer:tap="() => goto({ name: 'office' })"></div>
		<div class="stats-content">
			<div class="header">
				<div
					v-if="page === CME_GAME || page === ACCREDITATIONS"
					class="title"
				>{{ $t('accreditations') }}</div>
				<div
					v-else
					class="title"
				>{{ $t('my_stats') }}</div>
				<div v-if="page === 'stats'" class="close" v-hammer:tap="() => goto({ name: 'office' })"></div>
				<div v-if="page === ACCREDITATIONS" class="close back" v-hammer:tap="() => page = 'stats'"></div>
			</div>
			<diV class="content">
				<div class="page backward"></div>
				<div class="page backward"></div>
				<div class="page" v-scrollbar>
					<div>
						<div v-show="page === 'stats'" class="page-content">
							<player-infos @changePage="changePage" :user="user" />
						</div>
						<div v-show="page === ACCREDITATIONS" class="page-content">
							<player-accreditations
								@changePage="changePage"
								@setCmeGameId="setCmeGameId"
							/>
						</div>
						<div v-show="page === CME_GAME" class="page-content">
<!--							<Form
								type="cme"
							/>-->
							<div class="stack-form">
								<ValidationObserver
									ref="signUp"
									v-slot="{ handleSubmit }"
									tag="div"
									:key="step_id"
									class="validation-observer"
								>
									<FormsCME
										v-if="isFormElements"
										:context="getContext"
										class="form"
									/>

									<DisplayAny
										v-if="isContentTexts"
										:context="getContext"
										class="form"
										@back="page = 'stats'"
									/>

								</ValidationObserver>
							</div>
						</div>
					</div>
				</div>
			</diV>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PlayerInfos from '@/components/office/stats/player-infos'
import PlayerAccreditations from '@/components/office/stats/player-accreditations'
import FormsCME from '@/components/form/forms-cme'
import DisplayAny from '@/components/form/display-any'
import { ValidationObserver } from 'vee-validate'
import { _apollo } from '@/js/managers/_apollo'
import Form from "@/components/form/form"

export default {
	name: 'stats',
	components: {
		Form,
		DisplayAny,
		FormsCME,
		PlayerInfos,
		PlayerAccreditations,
		ValidationObserver,
	},
	data() {
		const ACCREDITATIONS = 'accreditations'
		const CME_GAME = 'cmeGame'
		const CREATE_PROGRESSION = 'CREATE_PROGRESSION'
		return {
			ACCREDITATIONS,
			CME_GAME,
			CREATE_PROGRESSION,
			page: 'stats',
			cmeGameId: null
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		...mapState({
			user: state => {
				return state.user.logged_user
			},
			manageUser: state => state.user.manageUser,
			manageForms: state => state.user.manageForms,
			isFormElements: state => {
				const { user: { manageForms: { step: { formElements = null } = {} } = {} } = {} } = state
				return !!formElements
			},
			isContentTexts: state => {
				const { user: { manageForms: { step: { contentTexts = null } = {} } = {} } = {} } = state
				return !!contentTexts
			},
			getContext: state => state.user.logged_user || {}
		}),
		step_id() {
			if (this.manageForms && this.manageForms.step && this.manageForms.step.id)
				return this.manageForms.step.id
			return null
		},
	},
	methods: {
		async setCmeGameId({ cme_game_id }) {
			if (!cme_game_id) return
			const { CME_GAME, CREATE_PROGRESSION } = this
			this.cmeGameId = cme_game_id
			const mutation = CREATE_PROGRESSION
			const variables = { gameId: cme_game_id }
			const { id:progressionId, currentMark: { step } = {} } = await _apollo({ mutation, variables })
			this.$store.commit('progressionId', { progressionId })
			await this.$store.dispatch('setFormStep', { step })
			this.page = CME_GAME
		},
		changePage({ page = null }) {
			this.page = page || this.page
		},
		goto(data) {
			const { CME_GAME, page } = this
			if (page !== CME_GAME) this.$router.push(data)
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.stats
	absolute 0
	flex center center
	transition 0.25s easeOutQuart
	&.stats-leave-to
		opacity 0
	&.stats-enter
		opacity 0
		.stats-content
			transform translateY(-48px)
	.stats-bg
		absolute 0
		background-color alpha(#61789E, 70%)
	.stats-content
		width 100%
		height 100%
		max-width 450px
		max-height 700px
		padding 8px 0
		transition 0.25s easeOutQuart
		.header
			height 40px
			.title
				display inline-block
				height 100%
				width 60%
				padding 0 24px
				background-color dark
				border-top-left-radius 8px
				border-top-right-radius 8px
				line-height 48px
				color #fff
				font-weight 700
			.close
				position absolute
				right 16px
				bottom 0
				width 56px
				height 40px
				background purple url(../../assets/img/profile/close-profile.svg) center center no-repeat
				background-size 24px auto
				border-top-left-radius 8px
				border-top-right-radius 8px
				cursor pointer
				&.back
					background-image url(../../assets/img/profile/back.svg)
					background-size 20px auto
		.content
			background-color dark
			border-radius 0 12px 12px 12px
			height calc(100% - 40px)
			padding 8px
			.page
				height calc(100% - 6px)
				// background-color #fff
				box-shadow 0 -2px 2px alpha(dark, 50%)
				&.backward
					height 3px
				.page-content
					height 100%
					padding 8px
					.box
						margin 0 0 16px 0
						box-shadow 1px 1px 2px alpha(dark, 25%)
						&.blue
							.box-title
								background-color #A6CAE2
								text-transform none
								color #1D6381
							.box-content
								background-color #E0EEFC
								.corner
									width 20px
									height 12px
									&:before
										left -1px
										top -1px
										border-width 12px 20px 0 0
										border-color #A6CAE2 transparent transparent transparent
						&.purple
							.box-title
								background-color #61789E
							.box-content
								background-color #C2D3EE
								.corner
									transform rotate(-10deg)
									&:before
										left 1px
										top -3px
										border-color #61789E transparent transparent transparent
						&.yellow
							.box-title
								background-color #FFC758
							.box-content
								background-color #FFE9D2
								.corner
									right auto
									left -3px
									&:before
										left 1px
										top -3px
										border-width 0 16px 16px 0
										border-color transparent #FFC758 transparent transparent
						&.red
							.box-title
								background-color #FF9677
							.box-content
								background-color #FFB099
								.corner
									width 20px
									height 12px
									&:before
										left -1px
										top -1px
										border-width 12px 20px 0 0
										border-color #FF9677 transparent transparent transparent
						.box-title
							color #fff
							height 40px
							padding 4px
							line-height 32px
							text-align center
							font-weight 700
							font-size 1.8rem
							text-transform uppercase
						.box-content
							padding 16px
							border 1px solid alpha(#000, 10%)

							.corner
								position absolute
								right -3px
								bottom -3px
								width 16px
								height 16px
								background-color #fff
								&:before
									content ''
									position absolute
									left -1px
									top -1px
									width 0
									height 0
									border-style solid
									border-width 16px 16px 0 0
									border-color #A6CAE2 transparent transparent transparent


							.user-infos.blue
								.info
									height 24px
									line-height @height
									margin 0 0 8px 0
									background-color #A6CAE2
									color #1D6381
									text-align center
									&.transparent
										background-color transparent
									&:last-child
										margin-bottom 0
								.separator
									height 2px
									margin 0 0 8px 0
									background-color #A6CAE2

							.button
								display block
								width 100%
								height 40px
								flex center center
								border-radius 16px
								text-align center
								line-height @height
								font-weight 700
								cursor pointer
								box-shadow 2px 2px 4px alpha(dark, 50%)
								user-select none
								&:active
									transform translateY(2px)
									box-shadow none
								&.purple
									background-color #61789E
									color #fff
								.triangle
									display inline-block
									margin 0 0 0 16px
									width 0
									height 0
									border-style solid
									border-width 8px 0 8px 16px
									border-color transparent transparent transparent #fff
							.progress-bar
								height 16px
								margin 16px auto 32px auto
								width calc(100% - 32px)
								background-color yellow
								border-radius 16px
								.progress
									width 50%
									height 100%
									background-color red
									border-top-left-radius 16px
									border-bottom-left-radius 16px
								.star
									position absolute
									left -16px
									top calc(50% - 36px)
									padding-left 3px
									width 64px
									height 64px
									color #fff
									text-align center
									line-height 70px
									font-size 2.4rem
									&:before
										content ''
										position absolute
										left 0
										top 0
										width 100%
										height 100%
										background url(../../assets/img/star-red.svg) center center no-repeat
										background-size 100% auto
										transform rotate(-12deg)
								.infos
									position absolute
									right 0
									bottom 16px
									height 24px
									color red
									font-size 1.4rem
									line-height @height
							.treated-patients
								flex center center
								border-top 1px solid #fff
								padding 16px 0 0 0
								color red
								line-height 24px
								.label
									width 50%
									white-space nowrap
									font-weight 700
								.value
									width 50%
									text-align right
									font-size 2rem
									.heart
										float right
										width 24px
										height 24px
										margin 0 0 0 8px
										background url(../../assets/img/profile/heart.svg) center center no-repeat
										background-size contain
			.window
				width 100%
				height 100%
				text-align left

				&.slide-leave-to
					transform translateX(-50px)

				.validation-observer
					height 100%

				.form
					display block
					height 100%
					padding 32px 0

					>>> form
						height 100%

						&.form-enter-active, &.form-leave-active
							transition 0.25s easeOutQuart
						&.form-enter
							transform translateX(100%)
							opacity 0
						&.form-leave-to
							transform translateX(-100%)
							opacity 0

						.line
							margin 0 0 12px 0 !important

						.label
							text-align left
							color white
							display block
							margin 0 0 4px 0px
							padding 0 0 0 16px
							text-transform capitalize

						.input
							display block
							width 100%
							padding 0 16px
							height 40px
							appearance none
							color dark
							border none
							background-color #fff
							border-radius 40px
							font inherit
							font-size 1.4rem
							color inherit
							outline none

							&:focus
								box-shadow 0 0 0 2px red

						select.input
							background #fff url(../../assets/img/chevron-down.svg) right 12px center no-repeat
							background-size 16px 16px

						.error
							z-index 10000
							position absolute
							background-color white
							display block
							min-height 24px
							flex flex-start center
							color #f44336
							font-weight 700
							font-size 1.2rem
							line-height unset
							border black solid 1px
							padding 8px 12px
							user-select none
							box-shadow 0 0 16px 2px alpha(#000, 50%)
							border-radius 8px
							&.checkbox
								left 30px
								top 0
							&.text-input,
							&.password-input
								left 16px
								top 70px
							&.select-input
								left 16px
								top 70px

						.images
							flex flex-start flex-start
							flex space-between flex-start
							flex-wrap wrap
							padding 8px 0 0 0

							.image
								width (220px / 3 - 12px)
								height (220px / 3 - 12px)
								margin 0 0 16px 0
								background-color #ddd
								border-radius 50%
								cursor pointer
								transition transform 0.25s easeOutQuart
								// overflow hidden

								&.selected
									box-shadow 0 0 0 4px red
									transform scale(1.1)

								img
									width 100%
									height 100%
									border-radius 50%

					.title
						color white
						margin 0 0 32px 0
						padding 0 0 8px 0
						border-bottom 2px solid #fff
						text-align left
						font-size 2rem
						text-transform uppercase
						font-weight 700

					button[type="submit"]
						position fixed
						left 2000px
						top 2000px
						opacity 0

					>>> .line
						margin 0 0 16px 0

						&:last-child
							margin 0


					>>> .buttons
						position absolute
						right 0
						bottom 0
						margin 0
						.ui-button
							margin 0 0 0 16px
							font-weight 700

					>>> .ql-align-center
						text-align left
				.close
					position absolute
					left 8px
					top 8px
					width 40px
					height 40px
					background url(../../assets/img/arrow-left.svg) center center no-repeat
					background-size 32px auto
					cursor pointer


</style>
