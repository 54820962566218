<template>
	<div class="medals">
		<div class="tab-content" v-scrollbar>
			<div>
				<div>
					<div class="medals-header">
						<div class="title">{{ $t('reference') }}</div>
						<div class="description">
							<div class="text">{{ $t('top_players') }}</div>
							<div class="stamp"></div>
						</div>
					</div>
					<div class="medals-content">
						<div
							v-for="(user, index) in users"
							class="rank"
							v-hammer:tap="() => openProfile(user)"
						>
							<div
								v-if="!isSearchSuccessfull"
								class="rank-position"
							>
								<span class="no">{{ index + 1 }}</span>
							</div>
							<div v-else class="no-rank-position"></div>
							<div class="rank-content" v-hammer:tap="() => selectUser(user)">
								<div class="info name">{{ userName(user) }}</div>
								<div class="info">{{ practiceField(user) }}</div>
								<div class="info">Level {{ level(user) }}</div>
							</div>
							<div class="rank-actions">
								<div class="score">{{ likesTotal(user) }} <div class="heart"></div></div>
								<div
									class="action"
									:class="[ actionClass(user) ]"
									v-hammer:tap="() => colleagueAction(actionClass(user), user)"
								>
									<div
										v-if="actionClass(user) === PENDING"
										class="pending-state"
									></div>
									<div
										v-else
										class="users"
										:class="[ actionClass(user) ]"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { mixinUser } from '@/components/mixins/user'

export default {
	name: 'medals',
	mixins: [ mixinUser ],
	props: [ 'isSearchSuccessfull' ],
	data() {
		return {
		}
	},
	methods: {
		'already-friend'({ user = null }) {
			const { REMOVE: action } = this
			this.emit({ action, user })
		},
		emit({ action = null, user = null }) {
			this.$emit('colleagues-manager', { action, user } )
		},
		colleagueAction(action, user) {
			if (action && user) this.emit({ action, user })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.medals
	darkblue = #61789E
	.tab-content
		height 100%
		background-color #fff

		.medals-header
			.title
				height 48px
				background-color #FBCBBD
				color red
				line-height @height
				white-space nowrap
				text-align center
				font-size 2.4rem
				font-weight 700
			.description
				flex flex-start flex-start
				margin 16px
				padding-bottom 16px
				border-bottom 2px solid #FBCBBD
				color #999
				.text
					width calc(100% - 80px)
					margin 0 16px 0 0
				.stamp
					width 64px
					height 64px
					background url(../../../assets/img/profile/stamp-heart.svg) center center no-repeat
					background-size contain
		.medals-content
			.rank
				flex flex-start flex-start
				padding 16px
				&:last-child:after
					display none
				&:nth-child(1) .rank-position
					background-image url(../../../assets/img/profile/rank-1.svg)
					.no
						left calc(50% - 20px)
						top 12px
				&:nth-child(2) .rank-position
					background-image url(../../../assets/img/profile/rank-2.svg)
					.no
						left calc(50% - 20px)
						top 12px
				&:nth-child(3) .rank-position
					background-image url(../../../assets/img/profile/rank-3.svg)
					.no
						left calc(50% - 20px)
						top 12px
				&:after
					content ''
					position absolute
					left 16px
					bottom 0
					right 16px
					border-bottom 1px solid darkblue
				.no-rank-position
					width 72px
					height 72px
					margin 0 16px 0 0
					background url(../../../assets/img/user-orange.svg) center center no-repeat
					background-size 50px 50px
				.rank-position
					width 72px
					height 72px
					margin 0 16px 0 0
					background url(../../../assets/img/profile/rank-x.svg) center center no-repeat
					background-size contain
					text-align center
					font-size 2.4rem
					line-height 24px
					color #fff
					.no
						position absolute
						left 25px
						top 15px
						width 40px
				.rank-content
					width 'calc(100% - %s)' % (72px + 16px + 72px)
					line-height 24px
					cursor pointer
					.info
						color #999
						&.name
							font-weight 700
							color #000
				.rank-actions
					width 72px
					text-align right
					.score
						flex center center
						margin 0 0 8px 0
						.heart
							display inline-block
							width 24px
							height 24px
							background url(../../../assets/img/profile/gold-heart.svg) center center no-repeat
							background-size 16px
					.action
						height 40px
						flex center center
						padding 0 8px
						border-radius 4px
						background-color darkblue
						cursor pointer
						color #fff
						font-size 1.8rem
						font-weight 700
						&.self
							display none
						&.add
							&:before
								content '+'
								font-size 1.2em
								height 30px
						&.remove
							cursor pointer
							background-color #FBCBBD
							&:before
								content '-'
								font-size 1.2em
								height 30px
						&.already-friend
							cursor pointer
							background-color #FBCBBD
							&:before
								content: url(../../../assets/img/check-orange.svg)
								display inline-block
								width 18px
								height 22px
						.users
							display inline-block
							margin 0 0 0 4px
							width 24px
							height 24px
							background url(../../../assets/img/profile/users.svg) center center no-repeat
							background-size contain
							&.pending
								background none
							&.already-friend
								background url(../../../assets/img/profile/users-orange.svg) center center no-repeat

.pending-state {
	position: relative;
	width: 8px;
	height: 8px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: pending-state 1s infinite linear alternate;
	animation-delay: .5s;
}

.pending-state::before, .pending-state::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}

.pending-state::before {
	left: -15px;
	width: 8px;
	height: 8px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: pending-state 1s infinite alternate;
	animation-delay: 0s;
}

.pending-state::after {
	left: 15px;
	width: 8px;
	height: 8px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: pending-state 1s infinite alternate;
	animation-delay: 1s;
}

@keyframes pending-state {
	0% {
		background-color: #9880ff;
	}
	50%,
	100% {
		background-color: #ebe6ff;
	}
}
</style>
