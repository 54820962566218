import Vue from 'vue'
import { isNoValue, upperCaseAllWords, removeNonLetters } from '@/js/utils/utils'
import { mapGetters, mapState } from 'vuex'

export const mixinUser = {
	props: [ 'users', 'user' ],
	data() {
		const ACCEPT = 'ACCEPT'
		const ADD = 'add'
		const ADDED = 'added'
		const ALREADY_FRIEND = 'already-friend'
		const PENDING = 'pending'
		const REFUSE = 'REFUSE'
		const REFUSED = 'refused'
		const REMOVE = 'remove'
		const SELF = 'self'
		return {
			ADDED,
			ACCEPT,
			ADD,
			ALREADY_FRIEND,
			PENDING,
			REFUSE,
			REFUSED,
			REMOVE,
			SELF,
			otherUser: null
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		...mapState({
			id: state => state.user.logged_user.id,
			friends: state => state.user.logged_user.friends,
			relationships: state => state.user.logged_user.relationships,
			incomingRelationships: state =>
				(state.user.logged_user.relationships || [])
					.reduce((a, c) => {
						const { id: userId } = state.user.logged_user
						const { accepted, requester: { userName, document, id } } = c
						return id !== userId && isNoValue(accepted)
							? [...a, {
								user : { userName, document, accepted, id }
							}]
							: a
					}, [])
		}),
	},
	methods: {
		actionClass({ user: { id = null } = {} }) {
			const { ADD, REMOVE, PENDING, SELF } = this
			if (id === this.id) return SELF
			const { friends = [], relationships = [] } = this
			const isFriend = friends.find(x => x.id === id)
			if (isFriend) return REMOVE
			const isRelation = relationships.find(x => [ x?.requestee?.id, x?.requester?.id ].includes(id))
			if (isRelation) return PENDING
			return ADD
		},
		isUserPending({ user = {} } = this.$props) {
			const { accepted } = user
			return isNoValue(accepted)
		},
		isAccepted({ user = {} } = this.$props) {
			const { accepted } = user
			return accepted === true
		},
		isRefused({ user = {} } = this.$props) {
			const { accepted } = user
			return accepted === false
		},
		openProfile({ user = null } = {}) {
			if (!user) return
			this.otherUser = user
		},
		selectUser({ user = null }) {
			if (!user) return
			this.$emit('selectUser', { user })
		},
		goto(data) {
			this.$router.push(data)
		},
		userName({ user = {} } = this.$props) {
			const { userName = '' } = user
			return userName || Vue.i18n.translate('user_name_missing')
		},
		practiceField({ user = {} } = this.$props) {
			const { profile: { practiceFieldName = Vue.i18n.translate('practice_field_missing') } = {} } = user
			return upperCaseAllWords(practiceFieldName)
		},
		practiceName({ user = {} } = this.$props) {
			const { profile: { clinic_name = '' } = {} } = user
			return clinic_name || Vue.i18n.translate('no_practice_name')
		},
		activeSince({ user = {} } = this.$props) {
			const { createdAt = Vue.i18n.translate('date_missing') } = user
			return Vue.dateFormat
				? Vue.dateFormat(createdAt, 'readableDate')
				: createdAt
		},
		progressBar({ user: { levelData = {} } = {} } = this.$props) {
			const { unitInCurrentLevel = 0, totalUnitForNextLevel = 0 } = levelData
			const percent = (unitInCurrentLevel / totalUnitForNextLevel || 0).toFixed(2) * 100
			return `width:${percent}%`
		},
		level({ user: { levelData = {} } = {} } = this.$props) {
			const { level = 0 } = levelData
			return level
		},
		unitsNeeded({ user: { levelData = {} } = {} } = this.$props) {
			const { totalUnitForNextLevel = 0 } = levelData
			return totalUnitForNextLevel
		},
		unitsInLevel({ user: { levelData = {} } = {} } = this.$props) {
			const { unitInCurrentLevel = 0 } = levelData
			return unitInCurrentLevel
		},
		unitsTotal({ user: { levelData = {} } = {} } = this.$props) {
			const { unit = 0 } = levelData
			return unit
		},
		likesTotal({ score = 0 } = this.$props || {}) {
			return score
		}
	}
}
