<template>
	<ValidationObserver
		ref="CME"
		v-slot="{ handleSubmit }"
		tag="div"
		class="cme-form"
	>
    <form
			class="form"
			method="post"
			ref="form"
			@submit.prevent="handleSubmit(submit)"
			@keyup.enter='handleSubmit(submit)'
		>
			<div
				v-for="group in formElementsGroups"
				:class="css(group)"
			>
				<div
					v-for="entry in getFormElement(group)"
				>
					<component
						:ref="getElementInputId(entry) ? `input-${getElementInputId(entry)}` : ''"
						:is="getElementType(entry)"
						:data="entry"
						@tap="tap"
					/>
					<component
						:is="getElementType(entry)"
						v-if="isWithConfirmation(entry)"
						:is-with-confirmation="true"
						:data="entry"
						@tap="tap"
					/>
				</div>
			</div>
			<div class="line buttons">
				<ui-button color="red" v-hammer:tap="() => handleSubmit(submit)">{{ capFirst($t('continue')) }}</ui-button>
			</div>
			<button type="submit"/>
		</form>
  </ValidationObserver>
</template>

<script>
	import { mixinForms } from '@/components/mixins/forms'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { _apollo } from '@/js/managers/_apollo'
	import { mapGetters } from 'vuex'
	import { removeAccessToken } from '@/js/jwt/jwt'
  import { removeStart, capFirst } from '@/js/utils/utils'
  import DialogBox from '@/components/game/dialog-box'
	import { isObject } from '@/js/utils/utils'

	export default {
		name: 'formsCME',
		mixins: [ mixinForms ],
    data() {
		  return {
		    fancybox: {
		      termsAndConditions: {
		        url: 'pdf/dummy.pdf'
          }
        },
		    termsAndConditionsText: null,
        termsLoading: false
      }
    },
		components: {
      DialogBox,
			ValidationObserver,
			ValidationProvider
		},
		computed: {
			...mapGetters([ 'app', 'game', 'user' ]),
		},
		methods: {
			capFirst,
			async back() {
				await removeAccessToken()
				await this.$store.dispatch('removeUser')
				await this.$router.push({ name: 'login' })
			},
			start() {
				this.$router.push({ name: 'home' })
			},
			async onError(error) {
				let { message } = error
				message = removeStart(message, ':')
				await this.$store.dispatch('add_notification', { status: 'error', message })
			},
			async submit() {
				const { submitDisabled, onError } = this
				if (submitDisabled) return
				this.submitDisabled = true
				const { progressionId, $refs: { signUp = null } = {} } = this
				if (!progressionId) {
					this.submitDisabled = false
					return
				}
				let mutation = 'SUBMIT_FORM'
				let answers = []
				const { $refs: { form = [] } = {}, $refs:refs } = this
				const inputs = Object.entries(refs)
					.filter(([key]) => key.startsWith('input-'))
					.map(x => x[1].pop())
				for (let element of inputs) {
					const { formElementId, value } = element
					const data = Array.isArray(value)
						? value.map(x => x.value)
						: [ isObject(value)
								? value.value
								: value
							]
					if (formElementId) {
						answers = [ ...answers, {
							formElementId,
							data
						}]
					}
				}
				let variables = { progressionId, answers }
				const { progression: { id } = {} } = await _apollo({ mutation, variables, onError })
				if (!id) {
					this.submitDisabled = false
					return
				}
				mutation = 'COMPLETE_STEP'
				variables = { progressionId: id }
				const result = await _apollo({ mutation, variables, onError })
				if (!result) {
					this.submitDisabled = false
					return
				}
				const { progression: { clientUser: { onboarding = null } = {} } = {}, gameCompleted, nextStep: { content:step } = {} } = result

				if (step) await this.$store.dispatch('setFormStep', { step })
				await this.$store.dispatch('get_current_user')

				this.submitDisabled = false
				this.formError = null
			},
      open_url(url) {
        if (url) {
          let win = window.open(url, '_blank')
          win.focus()
        }
        this.save()
      }
		}
	}
</script>

<style lang="stylus">
	@import '../../assets/css/stack-form.styl'
</style>
