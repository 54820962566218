<template>
	<div class="patient-file" :id="'patient-file-' + patient.id" :class="[ 'patient-' + patient.id, { active }, game_status ]">

		<div class="content">
			<div class="infos">
				<div class="name"><span class="label">{{ $t('name') }}</span><span class="value">{{ patient.document['patient name'] }}</span></div>
			</div>
			<div class="infos">
				<div class="gender"><span class="label">{{ $t('gender') }}</span><span class="value">{{ patient.document.gender }}</span></div>
				<div class="age"><span class="label">{{ $t('age') }}</span><span class="value">{{ patient.document.age }}</span></div>
			</div>
			<div class="infos">
				<div class="difficulty">
					<span class="label">{{ $t('difficulty') }}</span>
					<span class="value stars" :class="'color-' + patient.document.difficulty">
						<span v-for="i in 5" class="star" :class="{ full: i <= patient.document.difficulty }"></span>
					</span>
				</div>
				<div v-if="game_status === 'completed'" class="game-completed"></div>
			</div>
		</div>

		<div v-if="patient.document['scenario name']" class="scenario">
			<div class="text">{{ patient.document['scenario name'] }}</div>
		</div>
		<div v-if="!noModuleName && modules_name" class="modules">
			<div class="label">{{ $t('module') }} :</div>
			<div class="text">{{ noModuleName ? '' : modules_name }}</div>
		</div>

		<div class="picture">
			<div class="picture-content">
				<img v-if="id_photo" :src="id_photo">
				<img v-else class="user-placeholder" src="../../assets/img/user-placeholder.svg">
			</div>
		</div>

		<div class="paperclip"></div>
		<div class="bounding-box" v-hammer:tap="()=> set_active_patient()"></div>

		<div class="more">
			<div class="more-button" v-hammer:tap="()=> start()">{{ seePatient() }}</div>
			<div
				v-if="hasInformationSummary"
				class="more-button another-button" v-hammer:tap="()=> displayInformationSummary()">
				{{ $t('information_summary') }}
			</div>
		</div>
		<div class="close" v-hammer:tap="()=> close_active_patient()"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'patient-file',
	props: {
		patient: { default: null },
		noModuleName: { default: false },
	},
	computed: {
		...mapGetters([ 'patients', 'logged_user' ]),
		hasInformationSummary() {
			const { name } = this.$route
			if (name !== 'resources') return false
			const { contentTexts } = this.patient
			return !!contentTexts
				.filter(x => x.tags.includes('information-summary'))
				.length
		},
		active() {
			return this.patients.selected_game && this.patients.selected_game.id && this.patients.selected_game.id == this.patient.id
		},
		modules_name() {
			return this.patient.clientModules.map((m) => {
				if (m && m.document) return m.document.title
				return ''
			}).join(', ')
		},
		id_photo() {
			let media = this.patient.clientMedias.find((m) => { return m.tags.includes('identity-photo') })
			if (media) return this.$medias.get({ id: media.id })
			return ''
		},
		game_status() {
			const { logged_user: { achievement: { gameJournal = [] } = {} } = {} } = this
			return (gameJournal.find(g => g.gameId === this.patient.id) || {}).status || 'incomplete'
		}
	},
	methods: {
		displayInformationSummary() {
			const { contentTexts } = this.patient
			this.$emit('information-summary', contentTexts)
		},
		seePatient() {
			const gender = this.$i18n.translate(this.patient.document.gender)
			return this.$i18n.translate(`see_${gender}_patient`)
		},
		set_active_patient() {
			if (!this.active) this.$store.dispatch('set_selected_game', { selected_game: this.patient })
		},
		close_active_patient() {
			this.$store.dispatch('set_selected_game', { selected_game: false })
		},
		start() {
			this.$store.dispatch('set_patients_visible', { patients_visible: false })
			this.$store.dispatch('set_main_loader', { main_loader: true })
			setTimeout(() => this.$store.dispatch('set_main_loader', { main_loader: false }), 1500)
			this.$store.dispatch('launch_game', { game: this.patient })
			if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.patient-file
		.content .infos .value
			font-size 1.2rem
		.more .more-infos .info .info-label
			font-size 1.2rem


.patient-file
	display block
	background-color #fcf6df
	text-align left
	box-shadow -2px 3px 0px #d9d9d9
	user-select none
	&:nth-child(1n)
		.picture
			transform rotate(6deg) translate(8px, 4px)
			background-color yellow
	&:nth-child(2n)
		.picture
			transform rotate(-2deg) translate(2px, 8px)
			background-color red
	&:nth-child(3n)
		.picture
			transform rotate(-8deg) translate(7px, 6px)
			background-color purple
	&:nth-child(4n)
		.picture
			transform rotate(3deg) translate(3px, 6px)
			background-color #8BC34A
	&:nth-child(5n)
		.picture
			transform rotate(8deg) translate(10px, 5px)
			background-color blue

	&.active
		.bounding-box
			display none
		.more, .close
			display block
		.scenario .text, .modules .text
			-webkit-line-clamp initial

	&.completed
		.content .infos
			.stars
				.star
					background-image url(../../assets/img/patient-star-orange-empty.svg)
					&.full
						background-image url(../../assets/img/patient-star-orange-full.svg)
		.scenario
			background-color #638A4A
		.modules, .more
			background-color #B9CC8E
		.modules .text
			color darken(#638A4A, 25%)

	.picture
		position absolute
		left -8px
		top -4px
		width 80px
		height 80px
		background-color red
		border 4px solid #fff
		box-shadow -2px 2px 1px alpha(#000, 25%)
		transform rotate(-3deg) translate(6px, 2px)
		.picture-content
			flex center flex-end
			position absolute
			left 0
			top 0
			width 100%
			height 100%
			overflow hidden
			img
				display block
				height 100%
				width 100%
				&.user-placeholder
					width 80%
					height 80%
	.paperclip
		position absolute
		left 64px
		top -7px
		width 9px
		height 36px
		background url(../../assets/img/paperclip.svg) top left no-repeat
		background-size 100% auto
		transform rotate(20deg)
	.score
		position absolute
		right 0
		top -28px
		height 28px
		padding 0 8px
		background-color #8BC34A
		border-top-left-radius 8px
		border-top-right-radius 8px
		border 4px solid #fcf6df
		border-bottom none
		box-shadow -3px 0px 1px #d9d9d9
		color #fff
		font-size 1.1rem
		line-height @height
		flex center center
		&.none
			background-color gray
		&.failed
			background-color #ef5350
		.label
			display inline-block
			font-size 1rem
		.value
			display inline-block
			text-transform uppercase
			// margin-left 16px
			font-weight 700
	.content
		margin 0 0 0 85px
		font-size 1.4rem
		line-height 20px
		padding 4px 0 6px 0
		.infos
			flex flex-start center
			padding 2px 16px 2px 0
			white-space nowrap
			.name
				width 100%
			.gender
				width calc(100% - 80px)
				text-transform capitalize
			.age
				width 80px
			.difficulty
				width 100%
				.value
					padding-bottom 4px
			.label
				float left
				padding 0 8px 0 0
				width 55px
				background-color #fcf6df
				z-index 1
				text-align right
				font-size 1.0rem
				color #A2A2A2
			.value
				display block
				&:before
					content ''
					position absolute
					left 0
					bottom 2px
					right 0
					border-bottom 1px dotted gray

			.stars
				flex flex-start center
				&.color-1 .star
					background-image url(../../assets/img/stars/star-teal-empty.svg)
					&.full
						background-image url(../../assets/img/stars/star-teal-full.svg)
				&.color-2 .star
					background-image url(../../assets/img/stars/star-green-empty.svg)
					&.full
						background-image url(../../assets/img/stars/star-green-full.svg)
				&.color-3 .star
					background-image url(../../assets/img/stars/star-yellow-empty.svg)
					&.full
						background-image url(../../assets/img/stars/star-yellow-full.svg)
				&.color-4 .star
					background-image url(../../assets/img/stars/star-orange-empty.svg)
					&.full
						background-image url(../../assets/img/stars/star-orange-full.svg)
				&.color-5 .star
					background-image url(../../assets/img/stars/star-red-empty.svg)
					&.full
						background-image url(../../assets/img/stars/star-red-full.svg)
				.star
					height 16px
					width 16px
					background url(../../assets/img/stars/star-teal-empty.svg) center center no-repeat
					background-size 16px auto
					&.full
						background-image url(../../assets/img/stars/star-teal-empty.svg)

			.game-completed
				position absolute
				right 8px
				bottom -2px
				width 28px
				height 24px
				border-left 4px solid #FCF6DF
				background #FCF6DF url(../../assets/img/game-completed.svg) center center no-repeat
				background-size contain

	.scenario
		padding 8px
		background-color #89B6C8
		color #fff
		font-size 1.4rem
		line-height 16px
		text-align left
		.text
			display -webkit-box
			-webkit-line-clamp 1
			-webkit-box-orient vertical
			overflow hidden
			text-overflow ellipsis
	.modules
		padding 8px
		background-color #E1EFF5
		font-size 1.4rem
		line-height 16px
		color dark
		text-align center
		.label
			color gray
			font-size 1.2rem
		.text
			display -webkit-box
			-webkit-line-clamp 1
			-webkit-box-orient vertical
			overflow hidden
			text-overflow ellipsis
			color #89B6C8
			font-weight bold
	.more
		display none
		padding 16px 16px 32px 16px
		background-color #E1EFF5
		.more-infos
			width calc(100% - 96px)
			padding 8px
			border-top-right-radius 4px
			border-bottom-right-radius 4px
			background-color #DCC981
			color #fff
			.info
				flex flex-start center
				height 20px
				line-height 20px
				font-size 1.4rem
				white-space nowrap
				.info-label
					width 40%
					flex flex-end center
					text-align right
					.check
						display inline-block
						width 12px
						height 12px
						margin-left 4px
						border 1px solid #fff
						&.checked
							&:after
								content ''
								absolute -8px
								background url(../../assets/img/check.svg) center center no-repeat
								background-size 16px auto
								transform translate(4px, -5px)
				.info-value
					width calc(60% - 12px)
					min-height 21px
					margin-left 12px
					border-bottom 1px dotted #fff
		.more-button
			width 100%
			height 40px
			border-radius 4px
			background-color #61789E
			box-shadow -2px 2px dark
			flex center center
			text-align center
			color #fff
			/*text-shadow -1px -1px dark*/
			font-size 1.4rem
			cursor pointer
			&:active
				transform translateY(2px)
				box-shadow none
			&.another-button
				margin: 8px 0 0 0
				background-color #4D94B2
	.close
		display none
		position absolute
		left calc(50% - 16px)
		bottom -16px
		width 32px
		height 32px
		background yellow url(../../assets/img/chevron-up-white.svg) center center no-repeat
		background-size 24px 24px
		border-radius 50%
		cursor pointer
		z-index 2
		box-shadow -2px 3px #d9d9d9
	.bounding-box
		absolute 0
		z-index 2
		cursor pointer

</style>
