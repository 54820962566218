<template>
	<div class="stack-form">
		<ValidationObserver
			ref="FORM"
			v-slot="{ handleSubmit }"
			tag="div"
			:key="step_id"
			class="validation-observer"
		>
			<FormsCME
				v-if="isFormElements"
				:context="getContext"
				:type="type"
				class="form"
			/>
			<DisplayAny
				v-if="isContentTexts"
				:context="getContext"
				:type="type"
				class="form"
				@back="back"
			/>
		</ValidationObserver>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import FormsCME from '@/components/form/forms-cme'
import DisplayAny from '@/components/form/display-any'

export default {
	name: 'form',
	props: [ 'type' ],
	mixins: [],
	components: {
		DisplayAny,
		FormsCME,
		ValidationObserver,
	},
	computed: {
		...mapGetters([ 'app' ]),
		...mapState({
			user: state => {
				return state.user.logged_user
			},
			manageUser: state => state.user.manageUser,
			manageForms: state => state.user.manageForms,
			isFormElements: state => {
				const { user: { manageForms: { step: { formElements = null } = {} } = {} } = {} } = state
				return !!formElements
			},
			isContentTexts: state => {
				const { user: { manageForms: { step: { contentTexts = null } = {} } = {} } = {} } = state
				return !!contentTexts
			},
			getContext: state => state.user.logged_user || {}
		}),
		step_id() {
			if (this.manageForms && this.manageForms.step && this.manageForms.step.id)
				return this.manageForms.step.id
			return null
		},
	},
	methods: {
		back() {
		}
	}
}
</script>

<style scoped>

</style>
