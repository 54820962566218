<template>
	<div class="leaderboard">
		<div class="leaderboard-bg" v-hammer:tap="() => goto({ name: 'office' })"></div>
		<div class="leaderboard-content">
			<div class="header">
				<div class="tabs" :class="tab">
					<div class="tab medals" :class="{ active: tab === MEDALS }" v-hammer:tap="() => tabSelect(MEDALS)">
						<div class="icon"></div>
						{{ $t('leaderboard') }}
					</div>
					<div
						class="tab colleagues"
						:class="{ active: tab === COLLEAGUES }"
						v-hammer:tap="() => tabSelect(COLLEAGUES)"
					>
						<div class="icon"></div>
						<span :class="{ 'pending-text': isPending }">{{ $t('colleagues') }}</span>
					</div>
				</div>
				<div v-if="theUser" class="close back" v-hammer:tap="() => closeUserProfile()"></div>
				<div v-else class="close" v-hammer:tap="() => goto({ name: 'office' })"></div>
			</div>

			<div class="search" :class="tab">
				<input
					v-model="searchedProfile"
					type="text"
					class="input"
					:placeholder="searchMessage({ tab })"
					@input="handleSubmit({ tab })"
				>
			</div>
			<medals
				v-if="tab === MEDALS"
				class="content"
				@selectUser="viewUserProfile"
				@colleagues-manager="colleaguesManager"
				:users="getLeaderboardIndex"
				:is-search-successfull="searchSuccessfull"
			/>
			<colleagues
				v-if="tab === COLLEAGUES"
				class="content"
				@selectUser="viewUserProfile"
				@colleagues-manager="colleaguesManager"
				@tab-select="tabSelect"
				:users="getFriends"
			/>

			<transition name="player-profile" class="content">
				<div v-if="!colleaguesManagerState && theUser" class="player-profile">
					<div class="player-profile-content" v-scrollbar>
						<div>
							<player-infos
								:user="theUser"
								:isOtherUser="true"
							/>
						</div>
					</div>
					<div class="player-profile-bar">
						<div class="button back" v-hammer:tap="() => closeUserProfile()">{{ $t('back') }}</div>
						<div class="button colleague" :class="{ active: isColleague(theUser) }">
							<div
								v-if="isColleague(theUser)"
								class="text"
								v-hammer:tap="() => colleaguesManager(REMOVE)"
							>
								{{ $t('remove_colleague') }}
							</div>
							<div
								v-else
								class="text"
								v-hammer:tap="() => colleaguesManager(ADD)"
							>
								{{ $t('add_colleague') }}
							</div>
							<div class="users"></div>
						</div>
					</div>
				</div>
			</transition>

			<transition name="loading" class="content">
				<div v-if="loading" class="loading">
					<div class="loading-content">
						<div class="planet"></div>
						<div class="loader"></div>
					</div>
				</div>
			</transition>
		</div>

		<transition
			name="colleagues-manager"
			class="content"
		>
			<div
				v-if="colleaguesManagerState"
				class="colleagues-manager"
			>
				<div class="bg"></div>
				<div class="colleagues-manager-content">
					<template
						v-if="isState(ADD)"
					>
						<div class="users add"></div>
						<div
							class="white-box"
						>
							<p v-html="$t('want_colleague_request', { userName: userName(theUser) })"></p>
						</div>
						<div
							class="button"
							v-hammer:tap="() => send(theUser)"
						>
							{{ $t('yes_send_request') }}
						</div>
						<div
							class="button"
							v-hammer:tap="() => closeColleaguesManager()"
						>
							{{ $t('no_reconsidered') }}
						</div>
					</template>
					<template
						v-if="isState(PENDING)"
					>
						<div class="users add"></div>
						<div
							class="white-box"
							v-html="$t('username_will_appear', { userName: userName(theUser) })"></div>
						<div
							class="button"
							v-hammer:tap="() => closeColleaguesManager()"
						>
							{{ $t('OK') }}
						</div>
					</template>
					<template
						v-if="isState(ACTION_ON_PENDING)"
					>
						<div class="users remove"></div>
						<div
							class="white-box"
						>
							<p v-html="$t('cancel_with_user', { userName: userName(theUser) })"></p>
						</div>
						<div
							class="button"
							v-hammer:tap="() => remove(theUser)"
						>
							{{ $t('yes_cancel') }}
						</div>
						<div
							class="button"
							v-hammer:tap="() => closeColleaguesManager()"
						>
							{{ $t('no_reconsidered') }}
						</div>
					</template>
					<template
						v-if="isState(REMOVE)"
					>
						<div class="users remove"></div>
						<div
							class="white-box"
						>
							<p v-html="$t('want_to_remove', { userName: userName(theUser) })"></p>
						</div>
						<div
							class="button"
							v-hammer:tap="() => remove(theUser)"
						>
							{{ $t('yes_remove') }}
						</div>
						<div
							class="button"
							v-hammer:tap="() => closeColleaguesManager()"
						>
							{{ $t('no_reconsidered') }}
						</div>
					</template>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Medals from '@/components/office/leaderboard/medals'
import Colleagues from '@/components/office/leaderboard/colleagues'
import PlayerInfos from '@/components/office/stats/player-infos'
import Apollo from '@/apollo/apollo-client'
import { mixinUser } from '@/components/mixins/user'
import { resetDebounce } from '@/js/utils/utils'
import Vue from 'vue'

export default {
	name: 'leaderboard',
	mixins: [ mixinUser ],
	data() {
		const ADD = 'add'
		const COLLEAGUES = 'colleagues'
		const MEDALS = 'medals'
		const PENDING = 'pending'
		const ACTION_ON_PENDING = 'action on pending'
		const REMOVE = 'remove'
		return {
			ADD,
			REMOVE,
			MEDALS,
			COLLEAGUES,
			PENDING,
			ACTION_ON_PENDING,
			colleaguesManagerState: false,
			loading: true,
			tab: 'medals',
			theUser: null,
			getLeaderboardIndex: [],
			getFriends: [],
			searchedProfile: null,
			filterId: null,
			searchSuccessfull: false,
			timeoutSearchMedals: resetDebounce(800)
		}
	},
	watch: {
		async searchedProfile(newValue, oldValue) {
			if (newValue) return
			const { tab } = this
			if (tab === this.MEDALS) {
				this.timeoutSearchMedals(null)
				this.getLeaderboardIndex = await this.getLeaderboard()
				this.searchSuccessfull = false
			} else if (tab === this.COLLEAGUES) {
				const { friends } = this
				this.getFriends = friends
			}
		},
	},
	computed: {
		...mapGetters([ 'app' ]),
		...mapGetters({ User: 'user' }),
		isPending() {
			return !!this.incomingRelationships.length
		},
	},
	methods: {
		isAnyProfileToSearch({ tab }) {
			return ({
				medals: this.getLeaderboardIndex,
				colleagues: this.friends
			}[tab] || []).length > 0
		},
		searchMessage({ tab }) {
			return {
				medals: Vue.i18n.translate('global_search_profile'),
				colleagues: Vue.i18n.translate('search_colleague')
			}[tab] || Vue.i18n.translate('search_profile')
		},
		async 'search-medals'() {
			let { searchedProfile: value, filterId, User: { logged_user: { id:userId } = {} } = {} } = this
			if (!value) return
			value = value.toLowerCase()
			this.timeoutSearchMedals(async () => {
				const { data = [] } = await Apollo.query('CLIENT_USER_FILTERED', {
					filters:[{
						filterId,
						with: JSON.stringify({ value })
					}]
				})
				if (data.length) {
					this.searchSuccessfull = true
					this.getLeaderboardIndex = this.assignPracticeName(
						data.reduce((a, user) => user.id !== userId ? [ ...a, { user } ] : a, [])
					)
				} else {
					this.getLeaderboardIndex = this.getLeaderboard()
				}
			})
		},
		async 'search-colleagues'() {
			let { searchedProfile: value } = this
			if (!value) return
			const { friends } = this
			value = value.toLowerCase()
			const pattern = new RegExp(`.*${value}.*`)
			this.getFriends = friends.reduce((a, c) => c.userName.toLowerCase().match(pattern) ? [ ...a, c ] : a, [])
		},
		async handleSubmit({ tab }) {
			const func = `search-${tab}`
			if (this[func]) this[func]()
		},
		async remove({ user: { id: requesteeId } }) {
			const { id: requesterId } = this
			await this.$store.dispatch('deleteRelationship', { requesterId, requesteeId })
			await this.getRelationships()
			this.closeColleaguesManager()
		},
		async getRelationships() {
			const relationships = await Apollo.query('RELATIONSHIPS')
			await this.$store.dispatch('relationships', { relationships })
		},
		async send({ user: { id } }) {
			await this.$store.dispatch('sendColleagueRequest', { id })
			await this.getRelationships()
			const { PENDING } = this
			this.colleaguesManagerState = PENDING
		},
		isState(action) {
			return this.colleaguesManagerState === action
		},
		isColleague({ id }) {
			const { User: { logged_user: { friends = [] } = {} } = {} } = this
			return friends.find(x => x.id === id)
		},
		colleaguesManager({ action, user }) {
			const { ACTION_ON_PENDING, PENDING } = this
			this.theUser = user
			this.colleaguesManagerState = action === PENDING
				? ACTION_ON_PENDING
				: action
		},
		tabSelect(tab) {
			this.closeUserProfile()
			this.tab = tab
		},
		goto(data) {
			this.$router.push(data)
		},
		viewUserProfile({ user = null }) {
			this.theUser = user || this.theUser
		},
		closeUserProfile() {
			this.theUser = null
		},
		closeColleaguesManager() {
			this.theUser = null
			this.colleaguesManagerState = false
		},
		assignPracticeName(users) {
			const { User: { practice_fields } = {} } = this
			if (!practice_fields) return users
			return users
				.map(x => {
					const { user: { profile: { practice_field: { id } = {} } = {} } = {} } = x
					if (id) x.user.profile.practiceFieldName = (practice_fields.find(x => x.id) || {}).html || ''
					return x
				})
		},
		async getLeaderboard() {
			await this.$store.dispatch('get_practice_fields')
			const leaderboard = await Apollo.query('LEADERBOARD', { name: 'user_by_like' })
			return this.assignPracticeName(leaderboard)
		}
	},
	async mounted() {
		this.loading = true
		this.getLeaderboardIndex = await this.getLeaderboard()
		this.loading = false
		const result = await Apollo.query('CLIENT_FILTER_BY', { targetModel: 'Client::User' })
		this.filterId = result
			? (result.find(x => x.name === 'search_profile') || {}).id
			: null
		this.getFriends = this.friends
	},
	components: { Medals, Colleagues, PlayerInfos }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'
@import '../../assets/css/pending'

#app.mobile
	.leaderboard
		.leaderboard-content
			padding-bottom 0
			.player-profile, .loading
				height calc(100% - 112px)

.leaderboard
	darkblue = #61789E
	colleaguesManagerBgColor = #303C51
	userName = #FF6133
	absolute 0
	flex center center
	transition 0.25s easeOutQuart
	&.leaderboard-leave-to
		opacity 0
	&.leaderboard-enter
		opacity 0
		.leaderboard-content
			transform translateY(-48px)
	.leaderboard-bg
		absolute 0
		background-color alpha(#61789E, 70%)
	.leaderboard-content
		width 100%
		height 100%
		max-width 750px
		max-height 800px
		padding 8px 0
		transition 0.25s easeOutQuart
		// overflow hidden
		.header
			height 40px
			background-color dark
			box-shadow 0 -8px dark
			.tabs
				flex flex-start flex-end
				height 100%
				width 100%
				padding 0 8px
				&.medals:before
					background-color yellow
				&.colleagues:before
					background-color red
				&:before
					content ''
					position absolute
					left 0
					right 0
					bottom 0
					height 4px
				.tab
					display inline-block
					height 100%
					padding 0 16px
					margin 0 4px 0 0
					background-color dark
					border-top-left-radius 8px
					border-top-right-radius 8px
					line-height 40px
					color white
					font-weight 700
					cursor pointer
					user-select none
					@media only screen and (max-width: 320px)
						padding 0 8px
					&.medals
						background-color red
						.icon
							background-image url(../../assets/img/profile/rank-white.svg)
					&.colleagues
						background-color yellow
						.icon
							background-image url(../../assets/img/profile/users.svg)
					&.active
						cursor default
					.icon
						float left
						width 24px
						height 40px
						margin 0 8px 0 0
						background center center no-repeat
						background-size 24px auto
			.close
				position absolute
				right 8px
				top -2px
				width 56px
				height 32px
				background dark url(../../assets/img/profile/close-profile.svg) center center no-repeat
				box-shadow 0 0 0 2px white
				background-size 24px auto
				border-radius 8px
				cursor pointer
				&.back
					background-image url(../../assets/img/profile/back.svg)
					background-size 20px auto

		.search
			height 64px
			padding 16px
			&.medals
				background-color red
				::placeholder
					color red
					opacity 0.5
			&.colleagues
				background-color yellow
				::placeholder
					color yellow
					opacity 0.75
			.input
				width 100%
				height 32px
				padding 0 16px
				border-radius 40px
				border none
				background-color white
				font inherit
				color dark
				outline none
		.content
			height calc(100% - 104px)
			box-shadow 2px 6px 6px alpha(#000, 25%)
		.player-profile
			position absolute
			left 0
			right 0
			bottom 0
			top 112px
			height calc(100% - 120px)
			transition 0.25s easeOutQuart
			overflow hidden
			&.player-profile-enter, &.player-profile-leave-to
				.player-profile-content
					transform translateY(-100%)
				.player-profile-bar
					transform translateY(100%)
			.player-profile-content
				height calc(100% - 56px)
				background-color #fff
				transition 0.25s easeOutQuart
				>>> .player-infos
					padding 24px
			.player-profile-bar
				height 56px
				background-color dark
				flex center center
				transition 0.25s easeOutQuart
				.button
					height 40px
					flex center center
					margin 0 4px
					padding 0 16px
					border-radius 8px
					background-color darkblue
					cursor pointer
					color white
					user-select none
					font-size 1.8rem
					font-weight 700
					&:active
						transform translateY(2px)
					&.back
						background-color #E96161
					&.active
						background-color #F8A2A2
						.users:before
							content '-'
					.users
						display inline-block
						margin 0 0 0 12px
						width 36px
						height 24px
						background url(../../assets/img/profile/users.svg) right center no-repeat
						background-size 24px
						&:before
							content '+'
		.loading
			position absolute
			left 0
			right 0
			bottom 0
			top 112px
			height calc(100% - 120px)
			transition 0.25s easeOutQuart
			overflow hidden
			box-shadow 2px 6px 6px alpha(#000, 25%)
			&.loading-leave-to
				.loading-content
					transform translateY(100%)
			.loading-content
				height 100%
				flex center center
				flex-direction column
				background-color #61789E
				transition 0.25s easeOutQuart
				.planet
					width 120px
					height 120px
					background url(../../assets/img/profile/planet.svg) center center no-repeat
					background-size contain
				.loader
					margin 24px 0 0 0
					width 120px
					height 16px
					background url(../../assets/img/profile/loader.svg) center center no-repeat
					background-size contain


	.colleagues-manager
		absolute 0
		color white
		flex center center
		transition opacity 0.25s easeOutQuart
		overflow hidden
		&.colleagues-manager-enter, &.colleagues-manager-leave-to
			opacity 0
			.colleagues-manager-content
				transform translateY(-24px)
		.bg
			position absolute
			top 0
			left 0
			opacity 0.95
			width 100%
			height 100%
			background-color colleaguesManagerBgColor
		.colleagues-manager-content
			display flex
			flex-direction column
			justify-content center
			align-items center
			height 100%
			width 100%
			max-width 450px
			transition 0.25s easeOutQuart
			.users
				display block
				width 100px
				height 60px
				background url(../../assets/img/profile/users.svg) right center no-repeat
				background-size 60px
				&.add:before
					content '+'
					font-size 4em
				&.remove:before
					content '-'
					font-size 4em
			.white-box
				width calc(100% - 60px)
				background-color white
				padding 20px
				margin 30px 0 20px 0
				color black
				border-radius 8px
				text-align center
				p
					margin 0 0 4px 0
				.username
					color userName
			.button
				user-select none
				cursor pointer
				text-align center
				width calc(100% - 60px)
				background-color #61789E
				padding 10px
				margin 10px 0 0 0
				box-shadow 0 4px 4px 0 alpha(#000, 35%)
				border-radius 8px
				&:active
					transform translateY(2px)
				&.back
					background-color #E96161
				&.active
					background-color #F8A2A2

@media only screen and (max-width: 320px)
	.colleagues
		.tab-collleagues
			.tab-content
				padding 0px
</style>
