<template>
	<div class="view-office">
		<office @loaded="$emit('loaded')" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Office from '@/components/office'

export default {
	name: 'view-office',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	components: { Office }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.view-office
	height 100%
	width 100%
	transition 0.25s easeOutQuart
	transition-delay 0.25s

</style>
