<template>
	<div class="player-accreditations">
		<div
			v-for="group in groups"
			class="box"
		>
			<div class="box-title">{{ group.title }}</div>
			<div class="box-content">
				<div
					class="completed-label"
					:class="{ completed: completed(group) }"
				>
					{{ $t('completed') }} {{ group.gameCompleted.length }} / {{ group.total }}
				</div>
				<div v-if="group.claimed" class="cme-claimed">{{ $t('cme_claimed') }}</div>

				<div v-if="group.expanded" class="details">
					<div
						v-for="state in [ 'gameIncomplete', 'gameCompleted' ]"
						v-if="group[state].length"
						class="scenarios"
					>
						<div class="title">{{ $t( state.substring(4, state.length).toLowerCase() ) }}</div>
						<div class="games">
							<patient-file
								v-for="patient in group[state]"
								:patient="patient.game"
								:key="patient.id"
								:no-module-name="true"
							/>
						</div>
					</div>
				</div>

				<div class="buttons">
					<div
						v-if="completed(group) && group.canClaim && !group.claimed"
						class="button blue"
						v-hammer:tap="() => claimCME(group)"
					>{{ $t('claim_cme') }}</div>
					<div v-else class="button yellow" :class="{ less: group.expanded }" v-hammer:tap="() => toggle_group(group)">
						{{ $t('show_less_more', { what: group.expanded ? $t('less') : $t('more') }) }}
						<div class="triangle"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PatientFile from '@/components/patients/patient-file'
import {stackStringnify} from "@/js/utils/utils"
import Apollo from "@/apollo/apollo-client"

export default {
	name: 'player-accreditations',
	data() {
		const COMPLETED = 'completed'
		return {
			COMPLETED,
			groups: [],
			cmeGameId: null,
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'user', 'module' ]),
		...mapState({
			games: state => state.game.games
		})
	},
	methods: {
		async claimCME({ cme_game_id }) {
			if (!cme_game_id) return
			this.$emit('setCmeGameId', { cme_game_id })
		},
		completed(group) {
			return group.gameCompleted.length === group.total
		},
		toggle_group(group) {
			group.expanded = !group.expanded
		},
		async getGroups() {
			const {
				module: { modules = [] } = {},
				user
			} = this
			const completedGameIds = (user?.logged_user?.achievement?.gameJournal || [])
				.filter(x => x.status === 'completed')
				.map(x => x.gameId)
			const newModules = (modules || [])
				.map(module => {
					let {
						clientModule: { document: { cme_game_id, title = 'no title'} = {}} = {},
						gameCompleted = [],
						gameIncomplete = [],
					} = module
					const total = gameCompleted.length + gameIncomplete.length
					return {
						gameCompleted,
						expanded: false,
						gameIncomplete,
						total,
						title,
						cme_game_id,
					}
				})
			const ids = newModules.map(x => x.cme_game_id)
			// SECTION_SLUG filter
			const variables = {
				ids,
				filters: [ { filterId: '5fd7870658dddb0006fb32b9', with: stackStringnify({ value: 'accreditation' }) } ]
			}
			const games = ((await Apollo.query('CLIENT_GAME_FILTERED_CONTEXT', variables) || {}).data || [])
			return newModules
				.map(module => Object.assign(module, {
					canClaim: !!games.find(({ game: { id } = {}, unlocked }) => module.cme_game_id === id ? unlocked : false),
					claimed: !!completedGameIds.find(id => module.cme_game_id === id)
				}))
		}
	},
	async mounted() {
		await this.$store.dispatch('set_module_filters', { targetModel: 'Client::Module', property: 'all' })
		await this.$store.dispatch('set_module_default_filters', { property: 'accredited_module_equal', value: true })
		await this.$store.dispatch('set_module_filters_with', {
			values: [ this.$store.getters.module.filters ],
		})
		await this.$store.dispatch('get_module_filtered_context')
		this.groups = await this.getGroups()
	},
	components: { PatientFile }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.player-accreditations
	display block
	darkblue = #61789E
	lightblue = #C2D3EE

	> .title
		padding 8px 0
		margin 0 0 16px 0
		color #fff
		font-size 2rem
		text-align center
		text-transform uppercase
		font-weight 700

	.box
		margin 0 0 16px 0
		border 1px solid darkblue
		background-color #fff
		.box-title
			padding 8px 0
			background-color lightblue
			color darkblue
			text-align center
			text-transform uppercase
			font-weight 700
			font-size 0.8em
		.box-content
			padding 8px
			min-height 64px

			.completed-label
				color red
				padding-left 8px
				line-height 24px
				white-space nowrap
				text-transform uppercase
				font-size 1.4rem
				&.completed
					color #999

			.cme-claimed
				display inline-block
				padding 0 16px 0 8px
				background-color darkblue
				color #fff
				line-height 24px
				white-space nowrap
				text-transform uppercase
				border-top-right-radius 16px
				border-bottom-right-radius 16px
				font-size 1.4rem

			.details
				padding 8px 8px 64px 8px
				.scenarios
					> .title
						padding 8px 0
						border-top 1px solid dark
						color darkblue
						text-transform uppercase
						line-height 24px
					.games
						.patient-file
							margin 8px 0 40px 0
							&:last-child
								margin-bottom 0

			.buttons
				position absolute
				right 12px
				bottom 12px
				.button
					height 40px
					padding 0 16px
					border-radius 12px
					box-shadow 2px 2px 4px alpha(dark, 50%)
					cursor pointer
					user-select none
					color #fff
					font-size 1.4rem
					text-transform uppercase
					white-space nowrap
					line-height @height
					&.yellow
						background-color #FFB525
					&.blue
						background-color darkblue
					&:active
						transform translateY(2px)
						box-shadow none
					&.less
						.triangle
							transform rotate(180deg)
					.triangle
						display inline-block
						margin 0 0 0 8px
						width 0
						height 0
						border-style solid
						border-width 8px 8px 0 8px
						border-color #fff transparent transparent transparent



</style>
